<template>
  <div class="scene-section">
    <Header :showback="true">
      <template slot="title">每日答题</template>
    </Header>
    <div>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" @load="onLoad">
          <div class="cell" v-for="(record, index) in recordList" :key="index">
            <div class="van-doc-demo-block">
              <h2 class="van-doc-demo-block__title">
                {{ index + 1 }}.{{ record.name }}
              </h2>
              <van-radio-group
                v-model="checked[index]"
              >
                <van-cell-group inset>
                  <van-cell
                    v-for="(item, i) in record.answer"
                    :key="i"
                    :title="item.name"
                    clickable
                  >
                    <template #right-icon>
                      <van-radio :name="item.answer_id" />
                    </template>
                  </van-cell>
                </van-cell-group>
              </van-radio-group>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
      <div class="submit">
        <div class="submitbtn" @click="clickSubmit">提交</div>
      </div>
    </div>
    <van-popup
      v-model:show="showResult"
      closeable
      size="medium"
      type="primary"
      @close="closeShowResult"
      style="width: 90%; margin: 5% 0; padding: 5%; border-radius: 8px"
    >
      <div style="font-weight: bold; text-align: center; font-size: 25px">
        本次得分：{{ score }}
      </div>
      <div class="cell" v-for="(record, index) in recordList" :key="index">
        <div class="van-doc-demo-block">
          <h2>{{ index + 1 }}.{{ record.name }}</h2>
          <div v-for="(item, i) in record.answer" :key="i">
            <template v-if="success[index] == checked[index]">
              <van-cell v-if="success[index] == item.answer_id">
                <van-tag type="success">回答正确：{{ item.name }}</van-tag>
              </van-cell>
            </template>
            <template v-else>
              <van-cell v-if="success[index] == item.answer_id">
                <van-tag type="success">正确答案：{{ item.name }}</van-tag>
              </van-cell>
              <van-cell v-if="checked[index] == item.answer_id">
                <van-tag type="danger">回答错误：{{ item.name }}</van-tag>
              </van-cell>
            </template>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Header from "../../components/header.vue";
import { Toast } from "vant";
import { questionList, submitAnswer } from "@/request/api";
import $ from "jquery";
export default {
  name: "",
  components: {
    Header,
  },
  data() {
    return {
      refreshing: false,
      loading: false,
      finished: false,
      recordList: [],
      checked: [],
      address: "",
      success: [],
      showResult: false,
      score: 0,
    };
  },
  activated() {
    this.address = sessionStorage.getItem("address");
    this.refreshing = true;
  },
  methods: {
    closeShowResult() {
      this.showResult = false;
    },
    clickSubmit() {
      let _this = this;
      if (!this.address) {
        Toast("请使用币安智能链链接");
        return;
      }
      if (this.checked.length != this.recordList.length) {
        Toast("请完善答案");
        return;
      }
      // 计算得分
      let rate = parseInt(100 / parseInt(this.recordList.length));
      let socre = 0;
      let error = 0;
      this.success = [];
      $.each(this.checked, function (index, ele) {
        if (ele == null) {
          Toast("您的第" + (index + 1) + "道题还未完成");
          return;
        }
        if (_this.recordList[index].answer_id == ele) {
          socre += rate;
        } else {
          error += 1;
        }
        _this.success[index] = _this.recordList[index].answer_id;
      });
      if (error == 0) {
        socre == 100;
      }
      const params = {
        address: this.address,
        score: socre,
      };
      submitAnswer(params).then((res) => {
        if (res.code == 200) {
          Toast("参与成功,本次得分:" + socre);
          setTimeout(function () {
            _this.showResult = true;
            _this.score = socre;
            // Toast('正确答案');
            // _this.checked = success;
          }, 3000);
        } else {
          Toast("今日已参与完成");
        }
      });
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.currentPage = 1;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    onLoad() {
      this.getDataList();
    },
    getDataList() {
      const params = {
        address: this.address,
        page: this.currentPage,
        limit: "20",
      };
      questionList(params).then((res) => {
        if (this.refreshing) {
          this.recordList = [];
          this.refreshing = false;
        }
        this.loading = false;
        this.recordList = res.data;
        if (res.data.length < 20) {
          this.finished = true;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.scene-section {
  background: #0c1913;
  min-height: 100vh;
}
.demo-radio .van-doc-demo-block__title {
  margin-top: -8px;
}
.van-doc-demo-block .van-cell{
  color: #fff;
  background-color: #102B1D;
}
.van-doc-demo-block__title {
  margin: 0;
  padding: 32px 16px 16px;
  color: rgba(39, 204, 127, 1);
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.submit {
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  .submitbtn {
    width: 320px;
    height: 88px;
    border-radius: 16px;
    opacity: 1;
    background: rgba(39, 204, 127, 1);
    color: rgba(32, 40, 35, 1);
    font-size: 36px;
    font-weight: 700;
    font-family: "PingFang SC";
    text-align: center;
    line-height: 88px;
    margin-bottom: 50px;
  }
}
</style>